@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.breakSlider {
  width: 93%;
  margin: 0 auto;
  margin-bottom: 32px;

  .handleLabel {
    display: block;
    font-weight: $fw-bold;
    font-size: 14px;
    line-height: 24px;
    min-width: 68px;
    height: 40px;
    margin-top: 24px;
    margin-left: -23px;
  }
}
