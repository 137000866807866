@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.lettingAdSuccess {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f9f4;
  text-align: center;
  padding: 24px;
  color: $blue-dark-v100;
  z-index: 2;
  span {
    color: #3899cd;
    cursor: pointer;
    text-decoration: underline;
  }
  button {
    width: 272px !important;
  }
}
.successIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #29822f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px auto 17px;
  font-weight: $fw-normal;
}
.successTitle {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 28px;
  font-weight: $fw-bolder;
}
.successEmail {
  margin-bottom: 28px;
}
.successUpdated {
  margin-bottom: 20px;
}
