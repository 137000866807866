@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.breakContainer {
  display: flex;
  align-items: flex-start;
  color: $blue-dark-v100;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #dedede;
  width: 100%;
  padding-bottom: 14px;
  margin-top: 8px;
}
.dates {
  margin-top: 6px;
}
.numOfNights {
  font-size: 12px;
  font-weight: $fw-bold;
  color: #575757;
}
.infoString {
  font-weight: $fw-bold;
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  color: #575757;
}
.eventActions {
  margin-left: auto;
}
.editIcon {
  cursor: pointer;
  width: 24px;
  margin-right: 4px;
}
.deleteIcon {
  cursor: pointer;
  width: 24px;

  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.5;
  }
}
.dataLessWrapper {
  margin-right: auto;
  margin-top: 8px;
}
