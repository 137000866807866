@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.calendarContainer {
  display: flex;
  position: relative;
}
.calendarLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000030;
  z-index: 2;
  cursor: wait;
}
.calendarGrid {
  display: flex;
  flex-direction: column;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.tab {
  ul {
    justify-content: flex-start;
  }

  button {
    font-family: 'Heinemann HVN', sans-serif;
  }
}
.surveyContainer {
  margin-bottom: 24px;
}

.inlineMessageContainer {
  padding: 4px 24px;
  display: flex;
  flex-direction: column;

  > * {
    margin: 4px 0;
  }
}

.stretchParentPadding {
  width: calc(100% + 48px);
  margin-left: -24px;
}

.scrollTooltip {
  margin: 10px auto 0;
  opacity: 0;
  transition: opacity 1s;
}

.showScrollTooltip {
  opacity: 100%;
  transition: opacity 1s;
}
