@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.editorContainer {
  padding: 12px;
  background-color: #fff;
}
.counter {
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;

  p {
    margin: 0px;
  }
}
.saveButton {
  margin: 16px 0px !important;
}
.divider {
  margin: 8px 0px;
}
.iconButton {
  padding: 4px 6px !important;
  margin-left: 8px !important;
}
.userRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p {
    margin: 0px;
  }
}
