@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.buttonContainer {
  display: flex;
  align-items: center;
  button {
    padding: 4px 6px !important;
  }
  strong {
    margin: 0px 6px;
  }
}
