@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.mobileInfoWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;

  > div {
    min-width: 100%;
    background-color: white;
    margin-left: 0px;
    padding-bottom: 66px;
    @media screen and (max-width: 960px) {
      padding: 0px;
    }
  }
}
.mobileInitalPanelSelector {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 120px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 10px 20px -4px #000000;
}
.mobileSelectorHeader {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-bottom: unset !important;
}

.mobileToolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  width: 100%;
}

.summaryActions {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
  background-color: white;

  button {
    font-size: 14px !important;
    margin-right: 10px;
  }

  .addBookingIcon {
    height: 14px;
    margin-right: 8px;
  }

  .actions {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;

    button {
      font-size: 14px !important;
    }

    div {
      margin-right: 8px;
    }

    .buttonIcon {
      margin-right: 2px;
      height: 20px;

      &.add {
        height: 14px;
        margin-right: 8px;
      }
    }

    .onlyButtonIcon {
      cursor: pointer;
      margin-left: 8px;
    }
  }
}
