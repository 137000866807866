@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  padding-bottom: 14px;
  margin-top: 8px;
  color: $blue-dark-v100;
  font-size: 14px;
  line-height: 24px;

  .details {
    display: flex;
    align-items: center;
    @media screen and (max-width: $md) {
      align-items: flex-start;
      flex-direction: column;
    }

    .label {
      padding-right: 10px;
      @media screen and (max-width: $md) {
        padding: 0;
      }
    }
    .dates {
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      @media screen and (max-width: $md) {
        border-left: none;
        border-right: none;
        padding: 0;
      }
    }
    .numOfNights {
      font-size: 12px;
      font-weight: $fw-bold;
      color: #575757;
      padding-left: 10px;
      @media screen and (max-width: $md) {
        padding: 0;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    & > * {
      margin-top: 10px;
    }

    @media screen and (min-width: $md) {
      flex-direction: row;
      align-items: center;

      & > * {
        margin-right: 10px;
      }
    }

    .editIcon {
      cursor: pointer;
      width: 24px;
    }
    .deleteIcon {
      cursor: pointer;
      width: 24px;

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
  }
}

.partExItem {
  background: #1d3e73;
  display: flex;
  height: 100%;
  z-index: 10;
  position: relative;
  text-align: left;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px;

  @media screen and (min-width: $md) {
    margin: 16px 0;
    border-radius: 12px;
  }

  p {
    margin: 0;
    color: #fff;
  }

  .heading {
    font-size: 20px;
    font-weight: $fw-bolder;
    margin-bottom: 4px;
  }

  .imageWrapper {
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1.15) translateY(-1px);
    }
  }
}
