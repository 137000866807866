@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: r2px 4px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);

  span {
    color: #031545;
    font-size: 20px;
    margin-left: 16px;
    font-weight: $fw-bolder;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      cursor: pointer;
      width: 44px;
      height: 44px;
    }
  }
}
