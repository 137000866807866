@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.bookingContainer {
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.datePickerContainer {
  padding-top: 24px;
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    margin-bottom: 12px;
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__day-name) {
    width: 25px !important;
    line-height: 28px !important;
  }
}
