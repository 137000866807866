@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.mainContainer {
  padding-bottom: 70px;
}
.label {
  color: $blue-dark-v100;
  font-size: 18px;
  font-weight: $fw-bolder;
}
.text {
  font-size: 14px;
  font-weight: $fw-normal;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p {
    margin: 0px;
  }
}
.iconButton {
  padding: 4px 6px !important;
  margin-left: 10px;
}
.editorContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  > * {
    margin-bottom: 12px;
  }
}

.mainContainer {
  > * {
    margin-bottom: 12px;
  }
}
