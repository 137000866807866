@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.infoPanel {
  display: flex;
  flex-direction: column;
  height: 580px;
  border: 1px solid #e7e7e7;
  background-color: white;
  @media screen and (max-width: 960px) {
    height: 100vh;
  }
}
.infoPanelHeader {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid #dedede;
  p {
    margin: 0px;
  }
}
.infoPanelContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  .infoPanelMessage {
    margin: 12px 24px;
  }
}
.summaryView {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
}
.summaryActions {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #dedede;
  padding: 8px;
  background-color: white;
  z-index: 2;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  @media screen and (max-width: $sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  button {
    font-size: 14px !important;
  }

  .addBookingIcon {
    height: 14px;
    margin-right: 8px;
  }
}
.breakAndTooltipWrapper {
  width: 100%;
}
.divider {
  margin-bottom: 12px;
}
.closeButton {
  margin-left: auto;
  width: 24px;
  height: 24px;
  z-index: 2;
  svg {
    fill: $blue-dark-v100;
  }
}
.label {
  color: $blue-dark-v100;
  font-weight: $fw-normal;
  font-size: 16px;
  margin: 0px;
}
.dateString {
  font-weight: $fw-normal;
  font-size: 14px;
  margin: 0px;
  padding-left: 16px;
}
.infoString {
  font-weight: $fw-bold;
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  padding-left: 16px;
}
.iconButton {
  svg {
    margin-right: 6px;
  }
}
.letCheckBox {
  display: flex;
  margin-top: 10px;
}
.fullWidthButton {
  width: calc(100% - 32px);
  margin: 0px 16px !important;
}
.arrivalContainer {
  margin-bottom: 24px;
  button {
    margin-right: 12px !important;
  }
  & button:last-child {
    margin-right: 0px !important;
  }
}
.ctaButton {
  margin-top: 24px !important;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.bookingContainer {
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inlineMessage {
  margin-bottom: 8px;
}

.advancedInfoBox {
  line-height: 1.5;
  margin-bottom: 8px;
}
