@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.header {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid #dedede;
  p {
    margin: 0px;
  }
}
.closeButton {
  margin-left: auto;
  width: 24px;
  height: 24px;
  z-index: 2px;

  svg {
    fill: $blue-dark-v100;
  }
}
.label {
  color: $blue-dark-v100;
  font-weight: $fw-normal;
  font-size: 16px;
  margin: 0px;
}
