@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.editorContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  > * {
    margin-top: 12px !important;
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__day-name) {
    width: 28px !important;
    line-height: 28px !important;
  }
}
